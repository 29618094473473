export const Face = [
    {
       "value":"Striped Cat",
       "label":"Striped Cat"
    },
    {
       "value":"Grey Cat",
       "label":"Grey Cat"
    },
    {
       "value":"Black Cat",
       "label":"Black Cat"
    },
    {
       "value":"Black and White Cat",
       "label":"Black and White Cat"
    },
    {
       "value":"White Cat",
       "label":"White Cat"
    },
    {
       "value":"Skull Cat",
       "label":"Skull Cat"
    },
    {
       "value":"Zombie Cat",
       "label":"Zombie Cat"
    },
    {
       "value":"Alien Cat",
       "label":"Alien Cat"
    },
    {
       "value":"Ape Cat",
       "label":"Ape Cat"
    },
    {
       "value":"Bear Cat",
       "label":"Bear Cat"
    },
    {
       "value":"Golden Cat",
       "label":"Golden Cat"
    },
    {
       "value":"Bull Cat",
       "label":"Bull Cat"
    },
    {
       "value":"Harmony Cat",
       "label":"Harmony Cat"
    }
 ]
export const Neck = [
    {
       "value":"Tank Top",
       "label":"Tank Top"
    },
    {
       "value":"Turtleneck",
       "label":"Turtleneck"
    },
    {
       "value":"Scarf",
       "label":"Scarf"
    },
    {
       "value":"Collar",
       "label":"Collar"
    },
    {
       "value":"Gold Chain",
       "label":"Gold Chain"
    },
    {
       "value":"Vest",
       "label":"Vest"
    },
    {
       "value":"Sweater",
       "label":"Sweater"
    },
    {
       "value":"Necklace",
       "label":"Necklace"
    }
 ];
export const Eyes = [
    {
       "value":"Big Sunglasses",
       "label":"Big Sunglasses"
    },
    {
       "value":"Blue Eyes",
       "label":"Blue Eyes"
    },
    {
       "value":"Green Eyes",
       "label":"Green Eyes"
    },
    {
       "value":"Crazy Eyes",
       "label":"Crazy Eyes"
    },
    {
       "value":"Nerd Glasses",
       "label":"Nerd Glasses"
    },
    {
       "value":"Sleep Mask",
       "label":"Sleep Mask"
    },
    {
       "value":"Eye Mask",
       "label":"Eye Mask"
    },
    {
       "value":"Red Shades",
       "label":"Red Shades"
    },
    {
       "value":"Cool Glasses",
       "label":"Cool Glasses"
    },
    {
       "value":"Red Eyes",
       "label":"Red Eyes"
    },
    {
       "value":"Regular Shades",
       "label":"Regular Shades"
    },
    {
       "value":"Laser Eyes",
       "label":"Laser Eyes"
    },
    {
       "value":"VR",
       "label":"VR"
    }
 ];

export const HatHair = [
    {
       "value":"Japanese Bun",
       "label":"Japanese Bun"
    },
    {
       "value":"Bandana",
       "label":"Bandana"
    },
    {
       "value":"Headband",
       "label":"Headband"
    },
    {
       "value":"Stringy Hair",
       "label":"Stringy Hair"
    },
    {
       "value":"Hoodie",
       "label":"Hoodie"
    },
    {
       "value":"Pink Mohawk",
       "label":"Pink Mohawk"
    },
    {
       "value":"Kitty Bow",
       "label":"Kitty Bow"
    },
    {
       "value":"Hair Clips",
       "label":"Hair Clips"
    },
    {
       "value":"Brown Mohawk",
       "label":"Brown Mohawk"
    },
    {
       "value":"Blue Mohawk",
       "label":"Blue Mohawk"
    },
    {
       "value":"Fire Hat",
       "label":"Fire Hat"
    },
    {
       "value":"Arrow",
       "label":"Arrow"
    },
    {
       "value":"Cap",
       "label":"Cap"
    },
    {
       "value":"Tiara",
       "label":"Tiara"
    },
    {
       "value":"Pink Hat",
       "label":"Pink Hat"
    },
    {
       "value":"Vietnamese Hat",
       "label":"Vietnamese Hat"
    },
    {
       "value":"Red Hair",
       "label":"Red Hair"
    },
    {
       "value":"Knitted Hat",
       "label":"Knitted Hat"
    },
    {
       "value":"Beanie",
       "label":"Beanie"
    },
    {
       "value":"Magic Hat",
       "label":"Magic Hat"
    },
    {
       "value":"Headphones",
       "label":"Headphones"
    },
    {
       "value":"Flower Crown",
       "label":"Flower Crown"
    },
    {
       "value":"Unicorn Mohawk",
       "label":"Unicorn Mohawk"
    },
    {
       "value":"Crown",
       "label":"Crown"
    }
 ];
