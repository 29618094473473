import Sidebar from "./Sidebar";
import { PunksContextProvider } from "../contexts/punksContext";

function BaseLayout({ children }) {
  //   const { addFTMNetwork, isMetamask, isConnected } = useFTM();

  return (
    <div className="flex h-full flex-col w-full">
      <Sidebar />
      <PunksContextProvider>
        <main
          style={{ minHeight: "100%" }}
          className="text-white md:pl-sidebar w-full"
        >
          {children}
        </main>
      </PunksContextProvider>
    </div>
  );
}

export default BaseLayout;
