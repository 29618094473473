import cs from "classnames";

function Button({ text, className, style, onClick, selected, disabled }) {
  return (
    <button
      onClick={onClick}
      style={{ ...style, height: 23 }}
      className={cs(
        "outline-none font-medium leading-3 text-left text-start  bg-white ",
        {
          "border-gray-500 text-gray-600 cursor-default": disabled,
          "border-black hover:bg-black hover:text-white": !disabled,
          "text-black": !selected && !disabled,
          "bg-black": selected,
          "text-white": selected,
        },
        className
      )}
      disabled={disabled}
    >
      {text}
    </button>
  );
}

export default Button;
