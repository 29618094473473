import React, { useEffect } from "react";
import "./App.css";
import BaseLayout from "./layout/BaseLayout";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Symfoni } from "../hardhat/SymfoniContext";
import Minter from "./pages/Minter";
import Viewer from "./pages/Viewer";
// import Exchange from "./pages/Exchange";
import Attributes from "./pages/Attributes";
import Punks from "./pages/Punks";
import Detail from "./pages/Detail";

function App() {
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.removeAllListeners();
      window.ethereum.on("chainChanged", () => {
        document.location.reload();
      });
    }
  });

  function renderApp() {
    return (
      <>
        {true ? (
          <Switch>
            <Route exact path="/view">
              <Symfoni autoInit={true} showLoading={false}>
                <BaseLayout>
                  <Viewer />
                </BaseLayout>
              </Symfoni>
            </Route>
            <Route exact path="/harmonykitty/:id">
              <Symfoni autoInit={true} showLoading={false}>
                <BaseLayout>
                  <Detail />
                </BaseLayout>
              </Symfoni>
            </Route>
            <Route exact path="/attributes">
              <BaseLayout>
                <Attributes />
              </BaseLayout>
            </Route>
            <Route exact path="/kitties">
              <BaseLayout>
                <Punks />
              </BaseLayout>
            </Route>
            <Route exact path="*">
              <Symfoni autoInit={true} showLoading={false}>
                <BaseLayout>
                  <Minter />
                </BaseLayout>
              </Symfoni>
            </Route>
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/view">
              <BaseLayout>
                <Viewer />
              </BaseLayout>
            </Route>

            <Route exact path="/attributes">
              <BaseLayout>
                <Attributes />
              </BaseLayout>
            </Route>
            <Route exact path="*">
              <BaseLayout>
                <Minter />
              </BaseLayout>
            </Route>
          </Switch>
        )}
      </>
    );
  }

  return <BrowserRouter>{renderApp()}</BrowserRouter>;
}

export default App;
