import React, { useState } from "react";
import { useONE } from "../hooks/ONEHook";
import Button from "./Button";
import Info from "../assets/info";

export default function Modal({
  data,
  button,
  body,
  showSend = true,
  marginTop,
  width,
}) {
  const [showModal, setShowModal] = React.useState(false);
  const [toAddress, setAddress] = useState("");
  const { mintService, address } = useONE();

  async function send() {
    await mintService?.instance.transferFrom(address, toAddress, data.id);
  }

  function renderMeta(meta, body) {
    const metaList = [];
    let i = 0;
    for (const attribute of meta) {
      if (
        (body && attribute.trait_type !== "Body") ||
        (!body && attribute.trait_type === "Body")
      )
        continue;
      metaList.push(
        <div key={i}>
          {!body && (
            <span className="font-semibold">{attribute.trait_type}</span>
          )}
          <li className="text-sm">{attribute.value}</li>
        </div>
      );
      i++;
    }
    return metaList;
  }

  return (
    <>
      {showSend ? (
        <span
          className="cursor-pointer text-black  h-8  text-center underline "
          text=""
          onClick={() => setShowModal(true)}
        >
          {button ? button : "Send"}
        </span>
      ) : (
        <div onClick={() => setShowModal(true)}>
          <Info />
        </div>
      )}

      {showModal ? (
        <>
          <div
            style={{
              top: "50%",
              left: "50%",
              width: width || 280,
              marginTop: marginTop || -220,
              marginLeft: -130,
              position: "fixed",
            }}
            className="items-center flex overflow-x-hidden overflow-y-auto z-50 outline-none focus:outline-none"
          >
            {/*content*/}
            <div className="h-full text-black px-4 py-4 border-0 rounded-lg justify-between shadow-lg flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              {button ? (
                body
              ) : (
                <>
                  {showSend && (
                    <div className="mb-2 flex flex-col justify-between">
                      <span className="mb-4 text-lg font-semibold">
                        Send Kitty #{data.id}
                      </span>
                      {/*body*/}
                      <input
                        onChange={(e) => setAddress(e.target.value)}
                        className="pl-1 mb-5 w-full rounded border"
                        placeholder="Enter recipients address"
                      />
                      <div className="flex justify-around">
                        <Button
                          className="w-28 border text-center rounded-full"
                          onClick={() => setShowModal(false)}
                          text="Close"
                        />
                        <Button
                          onClick={() => send()}
                          text="Send"
                          className="w-28 border rounded-full text-center"
                        />
                      </div>
                    </div>
                  )}
                  {!showSend && (
                    <>
                      <div className="flex flex-col text-black">
                        <span className="my-3 font-bold">Type</span>
                        {renderMeta(data.attributes, true)}
                      </div>
                      <div className="flex flex-col text-black">
                        <span className="my-3 font-bold">Attributes</span>
                        {renderMeta(data.attributes, false)}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          <div
            onClick={() => setShowModal(false)}
            className="opacity-50 fixed inset-0 z-40 bg-black"
          ></div>
        </>
      ) : null}
    </>
  );
}
