import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import AssetCard from "../components/AssetCard";
import { fetchData } from "../lambda/lambda";
import Button from "../components/Button";
import qs from "qs";
import Filters from "./Filters";

const endpoint =
  "https://046w1m3dz6.execute-api.us-east-1.amazonaws.com/minted?page=";

function prepareFilters(stringFilters) {
  const curatedFilters = {};
  stringFilters.map((f) => {
    curatedFilters[f.type] = f.value;
    return true;
  });
  const _f = qs.stringify(curatedFilters);
  return _f;
}

function Punks() {
  const [punks, setPunks] = useState([]);
  const [loading, setLoading] = useState(false);
  const { pathname, search } = useLocation();
  let { page } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });
  const { push } = useHistory();
  const [filters, setFilters] = useState([]);
  const [total, setTotal] = useState(1);

  useEffect(() => {
    function handlePageClick() {
      fetchData(endpoint + page).then((data) => {
        setPunks(data.data);
        setTotal(data.count)
        setLoading(false);
      });
      setLoading(true);
    }
    if(!page) {
        push(
            `kitties?page=1`)
    }else {
        handlePageClick();

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  function handlePageClick(clickedPage, _filters) {
    let verifiedPage =
      isNaN(clickedPage) || !clickedPage ? 1 : parseInt(clickedPage);
      if (loading) return;
    if (_filters) {
      verifiedPage = 1;
    }
    let url = `${endpoint}` + verifiedPage;
    let __filters = _filters || filters;
    if (__filters?.length > 0) {
      const _f = prepareFilters(__filters);
      url += `&${_f}`;
    }
    setFilters(__filters);

    fetchData(url).then((data) => {
      setPunks(data.data);
      setTotal(data.count)
      setLoading(false);
    });
    setLoading(true);
    push(
      `${pathname}?${qs.stringify({
        page: verifiedPage,
      })}`
    );
  }

  return (
    <div className="w-full mb-20 flex flex-wrap items-center justify-center">
      <div className="flex flex-col pb-5  justify-between items-center bg-white pt-8 w-full border-b shadow-xl sticky left-0  right-0 top-0">
        <div className="flex flex-col md:flex-row justify-between w-full px-4 items-center">
          <span className="text-gray-900 md:ml-10 text-2xl font-bold">
            Minted Harmony Punks{" "}
          </span>
          <div className="flex border border-black mt-3 md:mt-0 rounded md:mr-16">
            <Button
              disabled={parseInt(page) === 1}
              onClick={() => handlePageClick(1)}
              text="First"
              className="border-r w-20 text-sm text-center px-0 h-7 border-black"
            />
            <Button
              disabled={parseInt(page) === 1}
              className="border-r w-20 text-center text-sm px-0 h-7  border-black"
              text="Previous"
              onClick={() => handlePageClick(parseInt(page) - 1)}
            />
            <Button
              disabled={punks.length !== 100}
              onClick={() => handlePageClick(parseInt(page) + 1)}
              text="Next"
              className="border-r w-20  text-sm text-center px-0 h-7 border-black"
            />
            <Button
              disabled={punks.length !== 100}
              onClick={() => handlePageClick(parseInt(Math.ceil(total/100)))}
              text="Last"
              className=" w-20 text-center text-sm px-0 h-7 border-black"
            />
          </div>
        </div>
        <div className="flex mt-5 justify-center flex-wrap">
          <Filters show={true} onChange={(f) => handlePageClick(1, f)} />
        </div>
      </div>
      {loading && (
        <span className="text-black w-full text-center mt-8 text-xl animate-pulse">
          Loading...
        </span>
      )}
      <div className="w-full pt-5 flex justify-center flex-wrap">
        {punks.map((p, i) => (
          <AssetCard
            punks={true}
            showSend={false}
            title={true}
            modal={false}
            data={p}
            index={i}
            key={i}
          />
        ))}
      </div>
    </div>
  );
}

export default Punks;
