import { useState, useEffect } from "react";
import { fetchData } from "../lambda/lambda";
import { useONE } from "../hooks/ONEHook";
import { useParams } from "react-router-dom";
import question from "../assets/question.png";
import Modal from "../components/Modal";


function Detail() {
  const { mintService } = useONE();
  const [data, setData] = useState(null);
  const { id } = useParams();

  function renderMeta(meta, body) {
    const metaList = [];
    let i = 0;
    for (const attribute of meta) {
      console.log(attribute.trait_type, body);
      if (
        (body && attribute.trait_type !== "Body") ||
        (!body && attribute.trait_type === "Body")
      )
        continue;
      metaList.push(
        <div className="flex flex-col items-center mb-2" key={i}>
          {!body && <span className="font-medium">{attribute.trait_type}</span>}
          <span className="text-sm">{attribute.value}</span>
        </div>
      );
      i++;
    }
    return metaList;
  }

  const url =
    "https://046w1m3dz6.execute-api.us-east-1.amazonaws.com/metadata?index=";

  useEffect(() => {
    fetchData(url + id).then((data) => {
      if (data) setData(data);
      console.log("dataaa");
    });

  }, [id, mintService?.instance]);

  return (
    <div
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/Background.png"})`,
        minHeight: "100vh",
        backgroundSize: "contain",
      }}
      className="flex justify-center"
    >
      <div className="flex bg-white flex-col flex-wrap max-w-2xl w-full items-center">
        <div className="text-black items-center flex flex-col px-6 py-8 border-black">
          <span className="text-black mb-4 text-center font-bold text-2xl">
            Harmony Kitty #{data?.id}
          </span>
          <div className="flex">
            <Modal data={data} showSend={true} />
          </div>
          <img
            alt="hpunk"
            className="w-44 h-44 cursor-pointer"
            onClick={() => window.open(`https://046w1m3dz6.execute-api.us-east-1.amazonaws.com/image?index=${id}`)}
            src={data?.image || question}
          />
          <span className="text-center font-bold text-lg mt-8">Type</span>
          {renderMeta(
            data?.attributes || [],
            true
          )}
          <span className="text-center font-bold text-lg mt-4">Attributes</span>
          {renderMeta(
            data?.attributes || []
          )}
        </div>
      </div>
    </div>
  );
}

export default Detail;
