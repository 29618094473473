import alien_cat from "../assets/Attributes/alien_cat.png";
import ape_cat from "../assets/Attributes/ape_cat.png";
import arrow from "../assets/Attributes/arrow.png";
import bandana from "../assets/Attributes/bandana.png";
import beanie from "../assets/Attributes/beanie.png";
import bear_cat from "../assets/Attributes/bear_cat.png";
import big_sunglasses from "../assets/Attributes/big_sunglasses.png";
import black_cat from "../assets/Attributes/black_cat.png";
import blackwhite_cat from "../assets/Attributes/blackwhite_cat.png";
import blue_eyes from "../assets/Attributes/blue_eyes.png";
import blue_mohawk from "../assets/Attributes/blue_mohawk.png";
import bull_cat from "../assets/Attributes/bull_cat.png";
import cap from "../assets/Attributes/cap.png";
import collar from "../assets/Attributes/collar.png";
import scarf from "../assets/Attributes/scarf.png"
import cool_glasses from "../assets/Attributes/cool_glasses.png";
import crazy_eyes from "../assets/Attributes/crazy_eyes.png";
import crown from "../assets/Attributes/crown.png";
import eye_mask from "../assets/Attributes/eye_mask.png";
import fire_hat from "../assets/Attributes/fire_hat.png";
import flower_crown from "../assets/Attributes/flower_crown.png";
import gold_chain from "../assets/Attributes/gold_chain.png";
import golden_cat from "../assets/Attributes/golden_cat.png";
import gray_cat from "../assets/Attributes/gray_cat.png";
import green_eyes from "../assets/Attributes/green_eyes.png";
import hair_clips from "../assets/Attributes/hair_clips.png";
import harmony_cat from "../assets/Attributes/harmony_cat.png";
import headband from "../assets/Attributes/headband.png";
import headphones from "../assets/Attributes/headphones.png";
import hoodie from "../assets/Attributes/hoodie.png";
import japanese_bun from "../assets/Attributes/japanese_bun.png";
import kitty_bow from "../assets/Attributes/kitty_bow.png";
import knitted_hat from "../assets/Attributes/knitted_hat.png";
import laser_eyes from "../assets/Attributes/laser_eyes.png";
import magic_hat from "../assets/Attributes/magic_hat.png";
import mohawk from "../assets/Attributes/mohawk.png";
import necklace from "../assets/Attributes/necklace.png";
import nerd_glasses from "../assets/Attributes/nerd_glasses.png";
import pink_hat from "../assets/Attributes/pink_hat.png";
import pink_mohawk from "../assets/Attributes/pink_mohawk.png";
import red_eyes from "../assets/Attributes/red_eyes.png";
import red_glasses from "../assets/Attributes/red_glasses.png";
import red_hair from "../assets/Attributes/red_hair.png";
import regular_shades from "../assets/Attributes/regular_shades.png";
import skull_cat from "../assets/Attributes/skull_cat.png";
import sleep_mask from "../assets/Attributes/sleep_mask.png";
import stringy_hair from "../assets/Attributes/stringy_hair.png";
import stripped_cat from "../assets/Attributes/stripped_cat.png";
import sweater from "../assets/Attributes/sweater.png";
import tank_top from "../assets/Attributes/tank_top.png";
import tiara from "../assets/Attributes/tiara.png";
import turtle_neck from "../assets/Attributes/turtle_neck.png";
import unicorn_mohawk from "../assets/Attributes/unicorn_mohawk.png";
import vest from "../assets/Attributes/vest.png";
import vietnamese_hat from "../assets/Attributes/vietnamese_hat.png";
import vr from "../assets/Attributes/VR.png";
import white_cat from "../assets/Attributes/white_cat.png";
import zombie_Cat from "../assets/Attributes/zombie_cat.png";


function Attributes() {
    function renderBody() {
      return (
        <div className="flex px-2.5 flex-col">
          <span className="font-bold mb-4 text-xl">Kitties</span>
          <div className="flex flex-wrap">
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={bull_cat} />
              </div>
              <span className="text-sm font-semibold">Bull Cat</span>
              <span className="text-center">12</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={bear_cat} />
              </div>
              <span className="text-sm font-semibold">Bear Cat</span>
              <span className="text-center">19</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={ape_cat} />
              </div>
              <span className="text-sm font-semibold">Ape Cat</span>
              <span className="text-center">105</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={alien_cat} />
              </div>
              <span className="text-sm font-semibold">Alien Cat</span>
              <span className="text-center">104</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={skull_cat} />
              </div>
              <span className="text-sm font-semibold">Skull Cat</span>
              <span className="text-center">192</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={zombie_Cat} />
              </div>
              <span className="text-sm font-semibold">Zombie Cat</span>
              <span className="text-center">184</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={harmony_cat} />
              </div>
              <span className="text-sm font-semibold">Harmony Cat</span>
              <span className="text-center">11</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={golden_cat} />
              </div>
              <span className="text-sm font-semibold">Golden Cat</span>
              <span className="text-center">4</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={black_cat} />
              </div>
              <span className="text-sm font-semibold">Black Cat</span>
              <span className="text-center">873</span>
            </div>
  
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={blackwhite_cat} />
              </div>
              <span className="text-sm font-semibold">Black and White Cat</span>
              <span className="text-center">865</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={stripped_cat} />
              </div>
              <span className="text-sm font-semibold">Striped Cat</span>
              <span className="text-center">845</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={white_cat} />
              </div>
              <span className="text-sm font-semibold">White Cat</span>
              <span className="text-center">941</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={gray_cat} />
              </div>
              <span className="text-sm font-semibold">Grey Cat</span>
              <span className="text-center">845</span>
            </div>
          </div>
        </div>
      );
    }
  
    function renderGlasses() {
      return (
        <div className="flex px-2.5 flex-col">
          <span className="font-bold mb-4 text-xl">Attributes</span>
          <div className="flex flex-wrap">
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={magic_hat} />
              </div>
              <span className="text-sm font-semibold">Magic Hat</span>
              <span className="text-center">34</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={unicorn_mohawk} />
              </div>
              <span className="text-sm font-semibold">Unicorn Mohawk</span>
              <span className="text-center">37</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={beanie} />
              </div>
              <span className="text-sm font-semibold">Beanie</span>
              <span className="text-center">46</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={arrow} />
              </div>
              <span className="text-sm font-semibold">Arrow</span>
              <span className="text-center">49</span>
            </div>
  
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={crown} />
              </div>
              <span className="text-sm font-semibold">Crown</span>
              <span className="text-center">51</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={headphones} />
                {/* <img alt="example" className="w-24 mr-1.5 h-24" src={f_elf_ears} /> */}
              </div>
              <span className="text-sm font-semibold">Headphones</span>
              <span className="text-center">62</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                {/* <img alt="example" className="w-24 mr-1.5 h-24" src={m_male_laser} /> */}
                <img alt="example" className="w-24 mr-1.5 h-24" src={laser_eyes} />
              </div>
              <span className="text-sm font-semibold">Laser Eyes</span>
              <span className="text-center">63</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                {/* <img alt="example" className="w-24 mr-1.5 h-24" src={m_magic} /> */}
                <img alt="example" className="w-24 mr-1.5 h-24" src={pink_hat} />
              </div>
              <span className="text-sm font-semibold">Pink Hat</span>
              <span className="text-center">77</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={tiara} />
              </div>
              <span className="text-sm font-semibold">Tiara</span>
              <span className="text-center">94</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={flower_crown}
                />
              </div>
              <span className="text-sm font-semibold">Flower Crown</span>
              <span className="text-center">107</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={hoodie}
                />
              </div>
              <span className="text-sm font-semibold">Hoodie</span>
              <span className="text-center">112</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                {/* <img alt="example" className="w-24 mr-1.5 h-24" src={m_hoodie} /> */}
                <img alt="example" className="w-24 mr-1.5 h-24" src={sweater} />
              </div>
              <span className="text-sm font-semibold">Sweater</span>
              <span className="text-center">120</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={red_hair} />
                {/* <img alt="example" className="w-24 mr-1.5 h-24" src={f_joint} /> */}
              </div>
              <span className="text-sm font-semibold">Red Hair</span>
              <span className="text-center">137</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={headband} />
                {/* <img alt="example" className="w-24 mr-1.5 h-24" src={f_vietnam} /> */}
              </div>
              <span className="text-sm font-semibold">Headband</span>
              <span className="text-center">154</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={vr} />
              </div>
              <span className="text-sm font-semibold">VR</span>
              <span className="text-center">159</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={cap}
                />
              </div>
              <span className="text-sm font-semibold">Cap</span>
              <span className="text-center">185</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={fire_hat} />
              </div>
              <span className="text-sm font-semibold">Fire Hat</span>
              <span className="text-center">186</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                {/* <img alt="example" className="w-24 mr-1.5 h-24" src={m_mask} /> */}
                <img alt="example" className="w-24 mr-1.5 h-24" src={vietnamese_hat} />
              </div>
              <span className="text-sm font-semibold">Vietnamese Hat</span>
              <span className="text-center">191</span>
            </div>
  
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={red_eyes}
                />
              </div>
              <span className="text-sm font-semibold">Red Eyes</span>
              <span className="text-center">200</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={kitty_bow} />
              </div>
              <span className="text-sm font-semibold">Kitty Bow</span>
              <span className="text-center">212</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={regular_shades}
                />
                {/* <img alt="example" className="w-24 mr-1.5 h-24" src={f_swim_goggles} /> */}
              </div>
              <span className="text-sm font-semibold">Regular Shades</span>
              <span className="text-center">222</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={pink_mohawk}
                />
                {/* <img alt="example" className="w-24 mr-1.5 h-24" src={f_swim_goggles} /> */}
              </div>
              <span className="text-sm font-semibold">Pink Mohawk</span>
              <span className="text-center">222</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={japanese_bun}
                />
              </div>
              <span className="text-sm font-semibold">Japanese Bun</span>
              <span className="text-center">226</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={eye_mask}
                />
              </div>
              <span className="text-sm font-semibold">Eye Mask</span>
              <span className="text-center">227</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={sleep_mask}
                />
              </div>
              <span className="text-sm font-semibold">Sleep Mask</span>
              <span className="text-center">264</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={red_glasses} />
              </div>
              <span className="text-sm font-semibold">Red Shades</span>
              <span className="text-center">265</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={knitted_hat} />
              </div>
              <span className="text-sm font-semibold">Knitted Hat</span>
              <span className="text-center">269</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={necklace} />
              </div>
              <span className="text-sm font-semibold">Necklace</span>
              <span className="text-center">296</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={blue_mohawk} />
              </div>
              <span className="text-sm font-semibold">Blue Mohawk</span>
              <span className="text-center">333</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={cool_glasses} />
              </div>
              <span className="text-sm font-semibold">Cool Glasses</span>
              <span className="text-center">334</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={crazy_eyes}
                />
              </div>
              <span className="text-sm font-semibold">Crazy Eyes</span>
              <span className="text-center">335</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                {/* <img alt="example" className="w-24 mr-1.5 h-24" src={m_pipe} /> */}
                <img alt="example" className="w-24 mr-1.5 h-24" src={bandana} />
              </div>
              <span className="text-sm font-semibold">Bandana</span>
              <span className="text-center">336</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={mohawk}
                />
              </div>
              <span className="text-sm font-semibold">Brown Mohawk</span>
              <span className="text-center">341</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={hair_clips}
                />
              </div>
              <span className="text-sm font-semibold">Hair Clips</span>
              <span className="text-center">376</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={stringy_hair}
                />
              </div>
              <span className="text-sm font-semibold">Stringy Hair</span>
              <span className="text-center">378</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={turtle_neck}
                />
              </div>
              <span className="text-sm font-semibold">Turtleneck</span>
              <span className="text-center">406</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={nerd_glasses}
                />
              </div>
              <span className="text-sm font-semibold">Nerd Glasses</span>
              <span className="text-center">413</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={big_sunglasses}
                />
              </div>
              <span className="text-sm font-semibold">Big Sunglasses</span>
              <span className="text-center">448</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={vest}
                />
              </div>
              <span className="text-sm font-semibold">Vest</span>
              <span className="text-center">458</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={blue_eyes} />
              </div>
              <span className="text-sm font-semibold">Blue Eyes</span>
              <span className="text-center">487</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                {/* <img alt="example" className="w-24 mr-1.5 h-24" src={m_vr} /> */}
                <img alt="example" className="w-24 mr-1.5 h-24" src={green_eyes} />
              </div>
              <span className="text-sm font-semibold">Green Eyes</span>
              <span className="text-center">480</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={scarf}
                />
              </div>
              <span className="text-sm font-semibold">Scarf</span>
              <span className="text-center">517</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={tank_top}
                />
              </div>
              <span className="text-sm font-semibold">Tank Top</span>
              <span className="text-center">526</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img
                  alt="example"
                  className="w-24 mr-1.5 h-24"
                  src={gold_chain}
                />
              </div>
              <span className="text-sm font-semibold">Gold Chain</span>
              <span className="text-center">553</span>
            </div>
  
            <div className="flex items-center mr-2.5 justify-center mb-5 flex-col">
              <div className="flex items-center">
                <img alt="example" className="w-24 mr-1.5 h-24" src={collar} />
              </div>
              <span className="text-sm font-semibold">Collar</span>
              <span className="text-center">915</span>
            </div>
          </div>
        </div>
      );
    }
  
    return (
      <div className="text-black w-full mb-20 mt-8 flex flex-col items-center">
        <div className="flex max-w-3xl w-full flex-col">
          {/* Bodies */}
          {renderBody()}
          {/* Head Accessories */}
          {renderGlasses()}
        </div>
      </div>
    );
  }
  
  export default Attributes;
  