import { useState, useContext, useEffect } from "react";
import Button from "../components/Button";
import AssetCard from "../components/AssetCard";
import { fetchData } from "../lambda/lambda";
import { useONE } from "../hooks/ONEHook";
import { PunksContext } from "../contexts/punksContext";
import ListModal from "../components/ListModal";

function Viewer() {
  const [wallet, setWallet] = useState("");
  const [assets, setAssets] = useState([]);
  const { mintService, address } = useONE();
  const [loading, setLoading] = useState(false);
  const { ownedPunks, isLoading } = useContext(PunksContext);
  const [showModal, setShowModal] = useState(false);
  const [modalId, setShowModalId] = useState(0);

  const url =
    "https://046w1m3dz6.execute-api.us-east-1.amazonaws.com/listmetadata?indices=";

  useEffect(() => {
    if (ownedPunks?.length > 0) {
      setAssets(ownedPunks);
    } else if (address) {
      setWallet(address || "");
    }
    setLoading(isLoading);
  }, [ownedPunks, address, isLoading]);

  async function fetchAssets() {
    setLoading(true);
    try {
      const ownerCount = await mintService.instance?.balanceOf(wallet);
      const ownerCountInt = ownerCount.toNumber();
      if (ownerCountInt > 0) {
        const _assetids = [];
        const range = [...Array(ownerCountInt).keys()];
        for (const i of range) {
          const assetId = await mintService.instance?.tokenOfOwnerByIndex(
            wallet,
            i
          );
          _assetids.push(assetId);
        }
        console.log(
          "list",
          _assetids.map((id) => id.toString())
        );

        const _assetMeta = await fetchData(
          url + _assetids.map((id) => id.toString()).join(",")
        );

        setAssets(_assetMeta);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  return (
    <div className="w-full mb-20 mt-8 flex flex-wrap items-center justify-center">
      {showModal && (
        <ListModal _id={modalId} handleHide={() => setShowModal(false)} />
      )}
      <div className="text-black flex flex-col w-full px-10 py-8 border-black">
        <div className="flex flex-col">
          <span className="font-semibold">
            Enter your wallet address to see your Harmony Punks.
          </span>
          <div className="flex mt-4 items-center">
            <input
              value={wallet}
              onChange={(t) => setWallet(t.target.value)}
              placeholder="wallet address"
              className="outline-none w-96 mr-5 pl-3 h-8 text-black border rounded-full border-black"
            />
            <Button
              onClick={fetchAssets}
              className="h-8 px-2 w-24 border text-center rounded-full self-center"
              text="Search"
            />
          </div>
        </div>
        {loading && (
          <span className="text-black w-full text-center mt-8 text-xl animate-pulse">
            Loading...
          </span>
        )}
        <div className="flex mt-8 flex-wrap">
          {assets
            .sort(function (a, b) {
              return a.id - b.id;
            })
            .map((u, index) => (
              <AssetCard
                modal={true}
                data={u}
                title={true}
                index={index}
                key={index}
                onList={(id) => {
                  setShowModalId(id);
                  setShowModal(true);
                }}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default Viewer;
