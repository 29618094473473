import {
    Face,
    Neck,
  Eyes,
  HatHair
} from "./filters/FilterOptions";
import Select from "react-select";
import { useState } from "react";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#9e9e9e",
    minHeight: "30px",
    height: "30px",
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: "30px",
    padding: "0 6px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "30px",
  }),
};
function Filters({ onChange }) {
  const [activeFilters, setActiveFilters] = useState([]);

  function handleFilterChange(a, type) {
    let _filters;
    if (a) {
      _filters = [
        ...activeFilters.filter((f) => f.type !== type),
        {
          value: a.value,
          label: a.label,
          type: type,
        },
      ];
    } else {
      _filters = [...activeFilters.filter((f) => f.type !== type)];
    }
    setActiveFilters(_filters);
    onChange(_filters);
  }
  return (
    <>
      <div className="w-40 mb-1 mr-2 text-black">
        <Select
          isClearable={true}
          styles={customStyles}
          onChange={(a) => handleFilterChange(a, "Face")}
          maxHeight={100}
          placeholder="Face"
          options={Face}
        />
      </div>

      <div className="w-40 mb-1 mr-2 text-black">
        <Select
          isClearable={true}
          styles={customStyles}
          onChange={(a) => handleFilterChange(a, "Neck")}
          placeholder="Neck"
          options={Neck}
        />
      </div>

      <div className="w-40 mb-1 mr-2 text-black">
        <Select
          isClearable={true}
          styles={customStyles}
          onChange={(a) => handleFilterChange(a, "Eyes")}
          placeholder="Eyes"
          options={Eyes}
        />
      </div>

      <div className="w-40 mb-1 mr-2 text-black">
        <Select
          isClearable={true}
          styles={customStyles}
          onChange={(a) => handleFilterChange(a, "Hat-Hair")}
          placeholder="Beard"
          options={HatHair}
        />
      </div>
    </>
  );
}

export default Filters;
